<template>
  <div class="main">
    <div class="modal">
      <div class="mineIcon desc">
        <img class="mineIcon1" src="@/assets/img/icon-1.webp" alt="icon" />
        <img class="mineIcon2" src="@/assets/img/icon-2.webp" alt="icon" />
        <img class="mineIcon3" src="@/assets/img/icon-3.webp" alt="icon" />
        <img class="mineIcon4" src="@/assets/img/icon-4.webp" alt="icon" />
        <img class="mineIcon5" src="@/assets/img/icon-5.webp" alt="icon" />
      </div>
      <div class="modal-left">
        <div class="mineIcon mobile">
          <img class="mineIcon1" src="@/assets/img/icon-1.webp" alt="icon" />
          <img class="mineIcon2" src="@/assets/img/icon-2.webp" alt="icon" />
          <img class="mineIcon3" src="@/assets/img/icon-3.webp" alt="icon" />
          <img class="mineIcon4" src="@/assets/img/icon-4.webp" alt="icon" />
          <img class="mineIcon5" src="@/assets/img/icon-5.webp" alt="icon" />
        </div>
        <div class="bonus-block">
          <div class="bonus-card">
            <div class="bonus-title">
              <h1>Играй в Vave</h1>
            </div>
            <div class="bonus-desc">
              <div class="bonus-item">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="40px"
                  viewBox="0 -960 960 960"
                  width="40px"
                  fill="#5f6368"
                >
                  <path
                    d="M160-80v-443.33H80v-233.34h216q-7-11-10.17-23.33-3.16-12.33-3.16-25.33 0-47.78 33.44-81.23Q349.56-920 397.33-920q23.67 0 45.34 9.5 21.66 9.5 37.33 27.17 15.67-18 37.33-27.34Q539-920 562.67-920q47.77 0 81.22 33.44 33.44 33.45 33.44 81.23 0 13-3.33 25.16-3.33 12.17-10 23.5h216v233.34h-80V-80H160Zm402.71-773.33q-20.38 0-34.21 13.78-13.83 13.79-13.83 34.17 0 20.38 13.78 34.21 13.79 13.84 34.17 13.84 20.38 0 34.21-13.79 13.84-13.79 13.84-34.17 0-20.38-13.79-34.21-13.79-13.83-34.17-13.83Zm-213.38 47.95q0 20.38 13.79 34.21 13.79 13.84 34.17 13.84 20.38 0 34.21-13.79 13.83-13.79 13.83-34.17 0-20.38-13.78-34.21-13.79-13.83-34.17-13.83-20.38 0-34.21 13.78-13.84 13.79-13.84 34.17ZM146.67-690v100h300v-100h-300Zm300 543.33v-376.66h-224v376.66h224Zm66.66 0h224v-376.66h-224v376.66Zm300-443.33v-100h-300v100h300Z"
                  />
                </svg>
                <div class="bonus-itemText">
                  <p>Создайте аккаунт</p>
                  <span>Получайте бонусы и вращайте колесо</span>
                </div>
              </div>
              <div class="bonus-item">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="40px"
                  viewBox="0 -960 960 960"
                  width="40px"
                  fill="#5f6368"
                >
                  <path
                    d="M366.67-120v-86.67H240v-66.66h86.67v-413.34H240v-66.66h126.67V-840h66.66v86.67h100V-840H600v90.33q53.33 12 86.67 54.17Q720-653.33 720-600q0 28.33-10.67 55.5-10.66 27.17-30.66 48.83 37.66 20.34 59.5 56.67Q760-402.67 760-360q0 63.33-44.67 108.33-44.66 45-108.66 45H600V-120h-66.67v-86.67h-100V-120h-66.66Zm26.66-393.33h173.34q36.33 0 61.5-25.5 25.16-25.5 25.16-61.17 0-36.33-25.16-61.5-25.17-25.17-61.5-25.17H393.33v173.34Zm0 240h213.34q36.33 0 61.5-25.5 25.16-25.5 25.16-61.17 0-36.33-25.16-61.5-25.17-25.17-61.5-25.17H393.33v173.34Z"
                  />
                </svg>
                <div class="bonus-itemText">
                  <p>Создайте аккаунт</p>
                  <span>Получайте бонусы и вращайте колесо</span>
                </div>
              </div>
            </div>
            <div class="bonus-img">
              <img src="banner.webp" alt="monkey" />
            </div>
          </div>
          <div class="bonus-footer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="40px"
              viewBox="0 -960 960 960"
              width="40px"
              fill="#fff"
            >
              <path
                d="M475-146.67q4.67 0 10.33-2.33 5.67-2.33 9-5.67l334-334.66Q841-502 847.17-518.11q6.16-16.11 6.16-32.22 0-16.67-6.16-32.84-6.17-16.16-18.84-28.5L651.67-788.33q-12.34-12.67-28.5-18.84-16.17-6.16-32.84-6.16-16.11 0-32.22 6.16Q542-801 529.33-788.33l-15.66 15.66L592.33-693Q606-679 615-660.67q9 18.34 9 39.67 0 39.33-29.17 68.17Q565.67-524 526.33-524q-23.33 0-40.5-7.33-17.16-7.34-30.95-21.23L381-626 202-447q-4.33 4.33-6.17 9.18-1.83 4.86-1.83 10.4 0 11.09 7.67 18.92 7.66 7.83 18.77 7.83 5.56 0 10.56-2.66 5-2.67 8.33-6l137.34-137.34L423.33-500 287-363.33q-4.33 4.33-6.17 9.5-1.83 5.16-1.83 10.5 0 10.66 8 18.66t18.67 8q5.33 0 10.33-2.33t8.33-5.67l137.34-137L508.33-415 372-278.33q-3.67 3.33-5.83 8.89-2.17 5.55-2.17 11.11 0 10.66 8 18.66t18.67 8q5.33 0 9.83-1.83t8.83-6.17l137.34-137L593.33-330 456-192.67q-4.33 4.34-6.17 9.5Q448-178 448-173.33q0 12 7.5 19.33 7.5 7.33 19.5 7.33ZM474.71-80q-34.38 0-61.21-24.5t-31.83-60.91q-34-4.92-57-27.92t-28-57q-34-5-56.5-28.5t-27.5-56.5q-37.34-5-61.34-31t-24-62q0-18 6.99-35.54 6.98-17.53 20.01-30.8L381-721l117 117q6 6.33 13.56 9.83 7.55 3.5 15.11 3.5 11.66 0 21.33-9.5 9.67-9.5 9.67-21.27 0-5.23-2.84-11.56-2.83-6.33-9.16-12.33l-143-143q-12.34-12.67-28.5-18.84-16.17-6.16-32.84-6.16-16.11 0-32.22 6.16Q293-801 280.37-788.43L132-639.67q-12.33 12.34-18 26.67-5.67 14.33-7 31.33t5 33.5q6.33 16.5 16.33 30.17l-48 48q-19-22.33-30-52.17-11-29.83-10.33-60.5.67-29.33 12.33-56.5Q64-666.33 85-687.33L232.67-835q22.66-22.33 51.03-33.33 28.36-11 58.16-11 29.81 0 57.97 11 28.17 11 50.5 33.33L466-819.33 481.67-835q22.66-22.33 51.03-33.33 28.36-11 58.16-11 29.81 0 57.97 11 28.17 11 50.5 33.33L875-659.33q22.33 22.33 33.67 51.02Q920-579.62 920-549.98q0 29.65-11.33 57.81Q897.33-464 875-441.67L541-108q-13.33 13.33-30.52 20.67Q493.29-80 474.71-80Zm-98.38-550.67Z"
              />
            </svg>
            <p>Наш амбассадор Джадд Трамп Чемпион мира 2019 года</p>
          </div>
        </div>
      </div>
      <div class="registration-form modal-right">
        <div class="form-header">
          <h2>Вход</h2>
          <h2 class="active">Регистрация</h2>
        </div>
        <form
          action="#"
          id="registrationForm"
          :class="{'form_disabled': isRequestSending}"
          @submit.prevent="submitForm"
        >
          <div class="form-group" style="width: 100%">
            <input type="text" id="nick" placeholder="НИКНЕЙМ" />
          </div>
          <div class="form-group" style="width: 100%">
            <input type="tel" id="phone" placeholder="Номер телефона" required v-model="phone" />
          </div>
          <div class="form-group" style="width: 100%">
            <input type="password" id="password" placeholder="Пароль" required v-model="password" />
            <div class="passwordShow">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d="M12 8.5C16.5 8.5 18 12 18 12C18 12 16.5 15.5 12 15.5C7.5 15.5 6 12 6 12C6 12 7.5 8.5 12 8.5Z"
                  stroke="#816AA6"
                  stroke-width="2"
                  stroke-linecap="round"
                ></path>
                <rect
                  x="6.34326"
                  y="16.2993"
                  width="14"
                  height="2"
                  rx="1"
                  transform="rotate(-45 6.34326 16.2993)"
                  fill="#816AA6"
                ></rect>
              </svg>
            </div>
          </div>
          <div class="form-group promo-code">
            <input type="text" id="promo" v-model="promocode" />
          </div>
          <div class="form-check">
            <label class="perm-check">
              <input type="checkbox" name="check" checked id="check" />
              <p>
                Мне больше 18 лет, и я согласен с
                <a href="#">Юридические условия</a> и
                <a href="#">Политика Конфиденциальности</a>
              </p>
            </label>
          </div>
          <div class="form-check">
            <label class="perm-check">
              <p>
                Этот сайт защищен с помощью reCAPTCHA и Google<a href="#">
                Политика и Условия</a
              >
                и <a href="#">Условия Сервиса</a>
              </p>
            </label>
          </div>
          <button type="submit" class="submit-btn">Войти</button>
        </form>

        <div class="block-close">
          <svg
            width="32px"
            height="32px"
            viewBox="0 0 16 16"
            fill="#fff"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M11.4697 12.5303C11.7626 12.8232 12.2374 12.8232 12.5303 12.5303C12.8232 12.2374 12.8232 11.7626 12.5303 11.4697L9.06066 8L12.5303 4.53033C12.8232 4.23744 12.8232 3.76256 12.5303 3.46967C12.2374 3.17678 11.7626 3.17678 11.4697 3.46967L8 6.93934L4.53033 3.46967C4.23744 3.17678 3.76256 3.17678 3.46967 3.46967C3.17678 3.76256 3.17678 4.23744 3.46967 4.53033L6.93934 8L3.46967 11.4697C3.17678 11.7626 3.17678 12.2374 3.46967 12.5303C3.76256 12.8232 4.23744 12.8232 4.53033 12.5303L8 9.06066L11.4697 12.5303Z"
              fill="#fff"
            ></path>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from 'vue-toastification'
import axios from 'axios'

export default {
  name: 'landing-page',
  data () {
    return {
      phone: '+7',
      password: '',
      country: 'RU',
      currency: 'RUB',
      promocode: 'TOR',
      checkbox: false,
      isRequestSending: false
    }
  },
  methods: {
    submitForm () {
      if (this.isRequestSending) {
        return
      }

      this.isRequestSending = true

      const data = {
        user: {
          country: this.country,
          currency: this.currency,

          bonus_code: this.promocode,
          captcha: '',
          parent_refcode: '',
          profile_attributes: {
            age_acceptance: true,
            terms_acceptance: true,
            game_currency: this.currency,
            receive_newsletters: false,
            receive_promos: false,
            last_name: '',
            first_name: '',
            receive_sms_promos: false,
            nickname: '',
            currency: this.currency,
            language: 'ru',
            country: this.country
          },
          phone: {
            country: this.country,
            number: `${this.phone}`
          },
          password: this.password,
          password_confirmation: this.password
        },
        lang: 'ru'
      }

      console.log(data)

      this.fetchData(data)
    },
    fetchData (data) {
      axios.post('/script.php', data, {
        headers: { 'Content-Type': 'application/json;charset=utf-8' }
      })
        .then((response) => {
          this.isRequestSending = false

          if (response.status === 200 || response.statusText === 'OK') {
            if (response.data) {
              const { token, url } = response.data

              if (token && token.length) {
                useToast().success('Успешная регистрация! Перенаправление...')
                document.location.href = url

                return
              }
            }

            useToast().error('Ошибка при регистрации')

            return
          }

          console.log('error', response)
          useToast().error('Ошибка при отправке запроса')
        })
        .catch((error) => {
          this.isRequestSending = false
          console.log('request error', error)
          useToast().error('Ошибка при отправке запроса')
        })
    }
  }
}
</script>

<style src="./style.scss" lang="scss"></style>
